<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click="close">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="modal-header" id="modalTitle">
          <slot name="header"> </slot>
        </header>
        <section class="modal-body" id="modalDescription">
          <slot name="body"> </slot>
        </section>
        <footer class="modal-footer">
          <slot name="footer">
            <button
              type="button"
              class="modal__btn"
              @click="close"
              aria-label="Close modal"
            >
              {{$t("modal.button")}}
            </button>
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(7, 7, 7, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
  &:hover {
    cursor: pointer;
  }
}

.modal {
  z-index: 16;
  background: $light-gray-color;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 60px 10px;
  width: calc(100% - 20px);
  max-width: 500px;
  @include for-tablet-portrait-up {
    padding: 67px 50px;
    max-width: 670px;
  }
  @include for-tablet-landscape-up {
    padding: 100px 77px;
    max-width: 786px;
  }
  &-header,
  &-footer {
    display: flex;
  }
  &-header {
    font-family: "Merriweather", serif;
    color: $super-dark-gray-color;
    justify-content: center;
    position: relative;
    // text-decoration: underline;
    @include H4();
    @include for-tablet-portrait-up {
      font-size: 30px;
      line-height: 38px;
    }
    @include for-tablet-landscape-up {
      @include H1();
    }
    @include for-desktop-up {
      @include H2();
    }
    &::after {
      display: block;
      position: absolute;
      top: 30px;
      left: 0;
      right: 0;
      width: 60px;
      height: 3px;
      border-radius: 4px;
      margin-left: auto;
      margin-right: auto;
      content: "";
      background: $main-color;
      // box-shadow: 0px 1px 4px rgba(14, 129, 232, 0.678);
      transition: width 0.3s ease-out;
      @include for-tablet-portrait-up {
        width: 80px;
      }
      @include for-tablet-landscape-up {
        top: 40px;
      }
      @include for-desktop-up {
        top: 60px;
      }
    }
  }
  &-footer {
    justify-content: center;
  }
  &-body {
    padding-top: 36px;
    padding-bottom: 45px;
    justify-content: center;
    font-family: "Manrope", sans-serif;
    @include P2();
    color: $super-dark-gray-color;
    text-align: center;
    @include for-tablet-portrait-up {
      padding-top: 50px;
      padding-bottom: 60px;
      @include H6();
    }
    @include for-tablet-landscape-up {
      @include H4();
      padding-top: 50px;
      padding-bottom: 70px;
    }
  }
  &__btn {
    font-family: "Manrope", sans-serif;
    text-decoration: none;
    width: 182px;
    height: 42px;
    @include P4();
    @include button();
    @include for-tablet-landscape-up {
      width: 200px;
      height: 46px;
      @include P3();
    }
  }
}
</style>
